<template>
  <div class="fifthSection">
    <div class="txt">
      <h1>Historia e statusit:</h1>
      <ul>

        <li v-for="(status, index) in order.activity" :key="index.status">
          <div>

            <h1>     
              <span class="teksti" v-html="translateStatus(status)"></span>
             <span class="pt-1">{{ formatDate(status.created_at) }}</span>
            </h1>
         
          </div>
        </li>

      </ul>
    </div>
  </div>
</template>


<script>
import moment from 'moment'

export default {
    props: ["myOrder"],
    data() {
        return {
            order:this.myOrder.data,
            statusi: null,
            ikona: null,
        }
    },
    methods: {
        formatDate(date) {
          return moment(date).format('YYYY-MM-DD H:mm')
        },
        translateStatus(data) {
                
             switch(data.status) {

               case 'Info received': 
                   return '<span class="ikona"><i class="fas fa-chevron-up"></i></span> Të dhënat e porosisë janë regjistruar'

               case 'Ready for pickup': 
                   return '<span class="ikona"><i class="fas fa-chevron-up"></i></span>  Porosia është gati për marrje'

               case 'Started': 
                 if(data.job_type == 'pickup') {
                         return '<span class="ikona">P</span>  Agjenti është nisur për marrje të porosisë';
                  } else if (data.job_type == 'delivery') {
                         return '<span class="ikona">D</span>  Agjenti është nisur për dorëzim të porosisë';
                  } else {
                         return data.status;
                  }

                case 'Successful': 
                    if(data.job_type == 'pickup') {
                          return '<span class="ikona">P</span> Porosia është marrë nga Agjenti me suksesë';
                    } else if (data.job_type == 'delivery') {
                          return '<span class="ikona">D</span> Porosia është dorëzuar me suksesë';
                    } else {
                          return data.status;
                    }

                case 'In Progress':
                    return '<span class="ikona"><i class="fas fa-chevron-up"></i></span>  Porosia është renditur për transport të mëtejshëm'

                case 'Assigned': 
                    if (data.job_type == 'delivery') {
                           return '<span class="ikona">D</span> Porosia është te agjenti i terenit';
                    } else {
                          return data.status;
                    }

                case 'Failed':
                   if(data.job_type == 'pickup') {
                          return '<span class="ikona failed">P</span> Marrja e porosisë ka dështuar për arsye: ' + '<p class="rs">' + data.description + '</p>';
                    } else if (data.job_type == 'delivery') {
                          return '<span class="ikona failed">D</span> Porosia nuk është dorëzuar për arsye: ' + '<p class="rs">' + data.description + '</p>';
                    } else {
                          return data.status;
                    }

                case 'Declined':
                    return '<span class="ikona failed">F</span>  Porosia është anuluar, dhe është gati për tu kthyer te dërguesi';

                case 'Returned To Sender':
                    return '<span class="ikona failed">R</span>  Dërgesa është rikthyer te dërguesi';

                case 'Hub scan':
                    return '<span class="ikona"><i class="fas fa-chevron-up"></i></span>  Porosia është pranuar në bazë';

                default: 
                return data.status;

                
                

                


             }

        },


    }
}
</script>