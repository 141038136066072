<template>
  <div class="detajetPorosia divBody">
    <div class="preloader" id="preloader" v-if="waitSpinner == true">
      <img src="../../assets/Images/spinner.svg" alt="" />
    </div>

    <div class="container headerCC" v-if="order.process_status < 8">
      <div class="row">
        <div class="col col-12">
          <div class="headerImage">
            <img src="../../assets/Images/npost-logo.png" alt="" />
            <p><span>N'</span>Llogaria</p>
          </div>
        </div>
      </div>
    </div>

    <div
      class="container animate__animated animate__fadeInRight"
      v-bind:class="{ mgBottom: order.process_status >= 8 }"
    >
      <div class="row">
        <div class="col col-12 col-lg-12" v-if="order.process_status < 8">
          <div class="firstSection">
            <div class="number txt">
              <p>#{{ order.barcode }}</p>
            </div>

            <div class="method txt">
              <p v-if="order.delivery_method == 'store-home'">
                <img src="../../assets/icon/store-icon.png" alt="" />
                Shitore
                <i class="fas fa-long-arrow-alt-right"></i>
                <img src="../../assets/icon/home-icon.png" alt="" />
                Shtëpi
              </p>
            </div>
          </div>
        </div>

        <div class="col col-12 col-lg-12" v-if="order.process_status < 8">
          <div class="secondSection">
            <div class="dsc">
              <div class="status txt">
                <h1>Statusi:</h1>

                <p v-if="order.process_status <= 3">Porosia e regjistruar</p>
                <p v-else-if="order.process_status >= 3 && order.process_status <= 5">
                  Porosia është në bazë
                </p>
                <p v-else-if="order.process_status >= 6 && order.process_status <= 7">
                  Porosia është nisur
                </p>
                <p v-else-if="order.process_status == 8">
                  Porosia është pranuar nga klienti
                </p>
                <p v-else-if="order.process_status == 9">Dërgesa ka dështuar</p>
                <p v-else-if="order.process_status == 10">
                  Dërgesa nuk u pranua
                </p>
                <p v-else-if="order.process_status == 11">
                  Porosia është anuluar
                </p>
                <p v-else-if="order.process_status == 12">
                  Produkti është rikthyer te dërguesi
                </p>
              </div>

              <progressbar :myOrder="myOrder"></progressbar>

              <div class="top price txt">
                <h1>Vlera:</h1>
                <p>{{ order.cash_amount }} EUR</p>
              </div>

              <div class="price txt" v-if="order.process_status >= 7">
                <h1>Postieri:</h1>
                <p style="margin-left: 10px; margin-top: -2px">
                  <a :href="'tel:' + order.agent.agent_phone">
                    <i class="fas fa-phone-volume"></i>
                  </a>
                </p>
              </div>
            </div>
            <div class="qrCodediv" v-if="order.process_status <= 8">
              <vue-qrcode
                id="qrCodeid"
                v-bind:value="qrGenerator"
                v-bind:color="qrColor"
                errorCorrectionLevel="H"
                data-toggle="modal"
                data-target="#exampleModalCenter"
                data-backdrop="static"
                data-keyboard="false"
              />
            </div>
          </div>
        </div>

        <!-- Buttons Collapse -->
        <div class="col col-12" v-if="order.process_status < 8">
          <div class="mainThirdSection">
            <div class="row">
              <!-- Buttons -->

              <!-- First -->
              <div class="col col-12">
                <div
                  class="
                    thirdSection
                    buttonsRD
                    animate__animated animate__fadeIn
                  "
                >
                  <div class="row">

                    <div class="col col-12 mb-4">

                      <changeEta :order="order" @updateDate="changeShippingDate"></changeEta>

                       <div class="card mt-4">
                        <div class="card-body">
                          <h5 class="card-title">
                            Adresa për dorëzimin e pakos në 
                            {{ this.order.receiver_address.address }} / 
                            {{ this.order.receiver_address.city }}
                          </h5>
                          
                          <p class="card-text">
                            Nëse adresa nuk është e saktë, ndryshoje
                          </p>
                    
                          <hr >
                          <button
                            v-if="order.process_status < 8"
                            id="addSa"
                            style="width:100%"
                            @click="afterModal()"
                            v-bind:disabled="isButtonDisabled"
                            data-toggle="modal"
                            data-target="#exampleModalCenterInstructions"
                            data-backdrop="static"
                            data-keyboard="false"
                          >
                            Saktëso adresën
                          </button>

                        </div>
                      </div>

                    </div>

                    <div class="col col-12">
                        <div class="d-flex">
                          <button
                            style="width:100%"
                            v-if="order.process_status <= 7"
                            data-toggle="modal"
                            data-target="#extraInstructions"
                            data-backdrop="static"
                            data-keyboard="false"
                          >
                            <i class="fas fa-inbox"></i> 
                            Instruksione për postierin
                          </button>
                         
                        </div>
                    </div>

                    <div class="col col-6 pl-0">
                    </div>
                  </div>
                </div>
              </div>
              <!-- End -->
              <!-- Buttons -->
            </div>
          </div>
        </div>
        <!--End -->

        <!-- Order location -->
        <div class="col col-12" v-if="order.process_status < 8">
          <div class="fifthSection">
            <div class="dscInfo">
              <div class="dsc">
                <div class="mapLocation">
                  <div id="map"></div>
                  <div
                    class="buttonsMap"
                    v-if="order.delivery_method == 'store-postomat'"
                  >
                    <button @click="gotoLocation()" id="fixedBtn">
                      <i class="fas fa-directions"></i> Navigacioni
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- End -->

        <!-- Pranuesi -->

        <div class="col col-12">
          <div
            class="fourthSection"
            v-bind:class="{ mgTop: order.process_status >= 8 }"
          >
            <div class="accordion" id="accordionExample">
              <div
                class="card"
                data-toggle="collapse"
                data-target="#collapseTwo"
                aria-expanded="true"
                aria-controls="collapseTwo"
              >
                <div class="card-header" id="headingOne">
                  <h5 class="mb-0">
                    <button class="btn btn-link" type="button">
                      <i class="fas fa-long-arrow-alt-down"></i> Pranuesi
                    </button>
                  </h5>
                </div>

                <div
                  id="collapseTwo"
                  class="collapse"
                  v-bind:class="{ show: order.process_status < 8 }"
                  aria-labelledby="headingOne"
                  data-parent="#accordionExample"
                >
                  <div class="card-body animate__animated animate__fadeIn">
                    <div class="derguesiDiv">
                      <div class="dsc name">
                        <h1>Emri dhe Mbiemri:</h1>
                        <p>{{ order.receiver_name }}</p>
                      </div>
                      <div class="dsc phone">
                        <h1>Numri kontaktues:</h1>
                        <p>{{ order.receiver_phone }}</p>
                      </div>
                      <div class="dsc address">
                        <h1>Adresa:</h1>
                        <p>
                          {{ order.receiver_address.address }} -
                          {{ order.receiver_address.city }}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- End -->

        <!-- Derguesi -->
        <div class="col col-12">
          <div
            class="fourthSection second"
            v-bind:class="{ mgTop: order.process_status >= 8 }"
          >
            <div class="accordion" id="accordionExample1">
              <div
                class="card"
                data-toggle="collapse"
                data-target="#collapseOne"
                aria-expanded="true"
                aria-controls="collapseOne"
              >
                <div class="card-header" id="headingOne">
                  <h5 class="mb-0">
                    <button class="btn btn-link" type="button">
                      <i class="fas fa-long-arrow-alt-down"></i> Dërguesi -
                      {{ order.sender.display_name }}
                    </button>
                  </h5>
                </div>

                <div
                  id="collapseOne"
                  class="collapse"
                  aria-labelledby="headingOne"
                  data-parent="#accordionExample1"
                >
                  <div class="card-body animate__animated animate__fadeIn">
                    <div class="derguesiDiv">
                      <div class="dsc name">
                        <h1>Emri dhe Mbiemri:</h1>
                        <p>{{ order.sender.display_name }}</p>
                      </div>
                      <div class="dsc phone">
                        <h1>Numri kontaktues:</h1>
                        <p>{{ order.sender.phone }}</p>
                      </div>
                      <div class="dsc address">
                        <h1>Qyteti:</h1>
                        <p>{{ order.pickup_address.city }}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- End -->
      </div>
    </div>

    <div class="container scontainer animate__animated animate__fadeInRight">
      <div class="row">
        <div class="col col-12 col-lg-12">
          <proceshistory :myOrder="myOrder"></proceshistory>
        </div>
      </div>
    </div>

    <div class="container dt" v-if="order.process_status < 8">
      <div class="row">
        <div class="col col-12 col-lg-12">
          <div class="fifthSection">
            <div class="instructionsDiv">
              <div class="dsc">
                <h1>Shënime shtesë:</h1>
                <p>
                  {{ order.description }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- QR CODE MODAL -->

    <!-- Modal -->
    <div
      class="modal fade"
      id="exampleModalCenter"
      tabindex="-1"
      role="dialog"
      aria-labelledby="exampleModalCenterTitle"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-body">
            <div class="imageModal">
              <vue-qrcode v-bind:value="qrGenerator" errorCorrectionLevel="H" />
            </div>
          </div>

          <div class="modal-footer">
            <button
              type="button"
              class="butoni butoni-cancel"
              data-dismiss="modal"
            >
              <i class="fas fa-times"></i> Mbylle
            </button>
          </div>
        </div>
      </div>
    </div>

    <!-- QR CODE MODAL -->

    <!-- CHANGE COORDINATES MODAL -->
    <div
      class="modal fade p-0 "
      style="overflow-y:hidden"
      id="exampleModalCenterInstructions"
      tabindex="-1"
      role="dialog"
      aria-labelledby="exampleModalCenterTitle"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered m-0 w-100"
       style="min-width:100%; height:100vh"
       role="document">
        <div class="modal-content h-100">
          <div class="modal-body">
            <div class="searchDiv" v-if="toggleSearch == true">
              <input
                type="text"
                v-model="searchingAddress"
                @input="searchmapMethod()"
                placeholder="Kërko adresën ose klikoni në hartë"
                id="searchInput"
                ref="searchInput"
                autocomplete="off"
              />

             <!-- <select v-model="kerkomeQytet" @change="onOption(kerkomeQytet)">
                <option value="" selected disabled hidden>
                  Selektoni komunën
                </option>
                <option v-for="(cities, index) in qytetet" :key="index">
                  {{ cities.cityName }}
                </option>
              </select> -->
            </div>

            <div class="vleratAdresa" id="vleratAdresa">
              <div class="mainBody">
                <ul id="listaAdresat">
                  <li
                    v-for="(search, index) in mapData.data"
                    :key="index.search"
                    @click="updateCoordinates(search.lat, search.lon)"
                  >
                    <h1>{{ search.display_name }}</h1>
                   <!-- <p v-if="toggleLatLng == true">
                      Lat: {{ search.lat }} / Long: {{ search.lon }}
                    </p> -->
                  </li>

                  <li v-if="thereisnodata == true">
                    <h1>
                      Të dhënat nuk u gjetën. Ju lutemi rishikoni adresën, ose
                      klikoni në hartë!
                    </h1>
                  </li>
                </ul>
              </div>
            </div>

            <div class="maps">
              <div id="map2">
                <div class="myLocation">
                  <div class="fr d-flex">
                    <button  @click="toggleSearchfunc()">
                      <i v-if="toggleSearch == false" class="fas fa-search"></i>
                      <i
                        v-if="toggleSearch == true"
                        class="fas fa-search-minus"
                      ></i>
                    </button>
                     <button class="ml-2" @click="getMyLocation()">
                      <img src="../../assets/icon/location-icon.png" alt="" />
                    </button>
                  </div>

                  <div class="scn d-flex">
                    <button 
                    class="mr-2 btn-exit"
                    data-dismiss="modal"
                    >
                      <i class="fas fa-times"></i>
                    </button>
                    <button
                      v-if="dragStarted == false"
                      type="button"
                      id="saveCoordinates"
                      class="btn-go"
                      data-dismiss="modal"
                      data-toggle="modal"
                      data-target="#AgreedAddress"
                      data-backdrop="static"
                      data-keyboard="false"
                    >
                      <i class="fas fa-check"></i>
                    </button>
                  </div>
                </div>

                <div
                  id="markerCenter"
                  class="map-marker-centered"
                  v-bind:class="{ markerActive: dragStarted == true }"
                >
                  <span id="spanMarker" v-if="dragStarted == true">
                    <i class="far fa-circle"></i>
                  </span>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>

    <div
      class="modal fade"
      id="AgreedAddress"
      tabindex="-1"
      role="dialog"
      aria-labelledby="exampleModalCenterTitle"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-body">
            <div class="alertDiv">
              <img src="../../assets/icon/caution-icon.png" alt="" />
              <h1>A jeni i sigurtë që kjo është adresa e saktë?</h1>
              <p>
                Vëmendje: Nëse adresa e ndryshuar është gabim, do të aplikohet
                kosto shtesë apo edhe të rikthehet në bazë!
              </p>
            </div>
          </div>

          <div class="modal-footer">
            <button
              type="button"
              class="butoni butoni-success"
              data-dismiss="modal"
              @click="UpdateRequestCoords()"
            >
              <i class="fas fa-check"></i> Po jam i sigurtë
            </button>
            <button
              type="button"
              class="butoni butoni-cancel"
              data-dismiss="modal"
              data-toggle="modal"
              data-target="#exampleModalCenterInstructions"
              data-backdrop="static"
              data-keyboard="false"
            >
              <i class="fas fa-undo-alt"></i> Dua ta rishikoj përsëri
            </button>
          </div>
        </div>
      </div>
    </div>

    <!-- CHANGE COORDINATES MODAL END -->

    <!-- GIVE DRIVER INSTRUCTION MODAL -->
    <div
      class="modal fade"
      id="extraInstructions"
      tabindex="-1"
      role="dialog"
      aria-labelledby="exampleModalCenterTitle"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-body">
            <div class="extra">
              <textarea
                name="extraInstructions"
                id="extraInstructions"
                cols="30"
                rows="10"
                placeholder="Dërgo instruksione më shumë për postierin, psh. Afër objektit të Raiffeisen Bank, kati përdhesë"
                v-model="driverInstructions"
              ></textarea>
            </div>
          </div>

          <div class="modal-footer scnd">
            <button
              type="button"
              class="butoni butoni-cancel"
              data-dismiss="modal"
              style="margin-right: 5px"
            >
              <i class="fas fa-times"></i> Anulo
            </button>

            <button
              type="button"
              class="butoni butoni-success"
              data-dismiss="modal"
              @click="sendInstructions()"
            >
              <i class="fas fa-paper-plane"></i> Dërgo
            </button>
          </div>

          <div class="lastInstructions">
            <h1>Instruksionet e fundit të dërguara:</h1>
            <div class="txt">
              <p>{{ order.delivery_instructions }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- GIVE DRIVER INSTRUCTION MODAL END -->

  </div>
</template>

<script>
import jQuery from "jquery";
window.jQuery = jQuery;
window.$ = jQuery;
import axios from "axios";
import Swal from "sweetalert2";
import VueQrcode from "vue-qrcode";
import progressbar from "./progess-bar.vue";
import proceshistory from "./process-history.vue";
import changeEta from "./change_shipping_date.vue";

export default {
  components: {
    VueQrcode,
    progressbar,
    proceshistory,
    changeEta,
  },
  props: ["myOrder"],
  data() {
    return {
      selectedDate: this.myOrder.data.dispatching_date,
      isButtonDisabled: null,
      waitSpinner: null,
      toggleCard: false,
      order: this.myOrder.data,
      editShippingDate:false,
      qrGenerator: this.myOrder.data.barcode,
      qrSource: null,
      qrColor: {
        dark: "#1578FE",
        light: "#fff",
      },
      kordinatat: [],
      driverInstructions: null,
      driverCoords: [],
      checkCoords: [],
      coordsInStreets: "",
      clickedCoordsAddress: "",
      positionMarker: "",
      positionMarkerOnUpdate: "",
      positionMarkerDriver: "",
      markerIcon: "",
      mymapPosition: "",
      driverMarkerIcon: "",
      polyline: "",
      mymap: "",
      streetSearch: "",
      mapData: "",
      kerkomeQytet: "",
      thereisnodata: false,
      toggleLatLng: false,
      dragStarted: null,
      toggleSearch: false,
      qytetet: [
        {
          cityName: "Artanë",
          cityLat: "42.608808320386984",
          cityLon: "21.440654734077196",
        },
        {
          cityName: "Besianë",
          cityLat: "42.91080994271356",
          cityLon: "21.195373293357928",
        },
        {
          cityName: "Burim",
          cityLat: "42.14430578725532",
          cityLon: "21.293812225356575",
        },
        {
          cityName: "Deçan",
          cityLat: "42.54031788132805",
          cityLon: "20.288321829739363",
        },
        {
          cityName: "Dragash",
          cityLat: "42.06152149231761",
          cityLon: "20.651109095931172",
        },
        {
          cityName: "Drenas",
          cityLat: "42.62108987508032",
          cityLon: "20.890442162610317",
        },
        {
          cityName: "Ferizaj",
          cityLat: "42.37020371367143",
          cityLon: "21.14826768159554",
        },
        {
          cityName: "Fushë Kosovë",
          cityLat: "42.63774588671655",
          cityLon: "21.09351065371292",
        },
        {
          cityName: "Gjakovë",
          cityLat: "42.384207675395444",
          cityLon: "20.427869992011132",
        },
        {
          cityName: "Gjilan",
          cityLat: "42.464002244978275",
          cityLon: "21.46955794185106",
        },
        {
          cityName: "Kastriot",
          cityLat: "42.6872386765646",
          cityLon: "21.066003739136963",
        },
        {
          cityName: "Kaçanik",
          cityLat: "42.22792707155417",
          cityLon: "21.256895528195958",
        },
        {
          cityName: "Klinë",
          cityLat: "42.6193871262688",
          cityLon: "20.57816485658724",
        },
        {
          cityName: "Leposaviq",
          cityLat: "43.101516817094755",
          cityLon: "20.80078994823001",
        },
        {
          cityName: "Lipjan",
          cityLat: "42.52593643422136",
          cityLon: "21.122854693130666",
        },
        {
          cityName: "Malishevë",
          cityLat: "42.48402728507205",
          cityLon: "20.742227955190625",
        },
        {
          cityName: "Pejë",
          cityLat: "42.66003677989915",
          cityLon: "20.28724565875144",
        },
        {
          cityName: "Prishtinë",
          cityLat: "42.66296595391157",
          cityLon: "21.165309630126913",
        },
        {
          cityName: "Prizren",
          cityLat: "42.217198123497155",
          cityLon: "20.743531861722637",
        },
        {
          cityName: "Rahovec",
          cityLat: "42.39982562180812",
          cityLon: "20.652943209868603",
        },
        {
          cityName: "Skenderaj",
          cityLat: "42.74761496535727",
          cityLon: "20.788539625398723",
        },
        {
          cityName: "Shtërpcë",
          cityLat: "42.24090624831328",
          cityLon: "21.02581499446747",
        },
        {
          cityName: "Shtime",
          cityLat: "42.43285135123636",
          cityLon: "21.040058401303362",
        },
        {
          cityName: "Therandë",
          cityLat: "42.362430157384225",
          cityLon: "20.83187998859898",
        },
        {
          cityName: "Viti",
          cityLat: "42.32215061148272",
          cityLon: "21.359017025791054",
        },
        {
          cityName: "Vushtrri",
          cityLat: "42.82669754898643",
          cityLon: "20.97032525976591",
        },
        {
          cityName: "Zubin Potok",
          cityLat: "42.914832128392504",
          cityLon: "20.689747385650477",
        },
        {
          cityName: "Zveçan",
          cityLat: "42.91076628023563",
          cityLon: "20.838523844889384",
        },
      ],
      info: "",
      searchingAddress: "",

      requestHeaders: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + this.$route.query.access_token,
        Accept: "application/json",
      }

    };
  },

  computed: {
    barcode() {
      return this.$route.params.barcode;
    },
  },

  methods: {

    changeShippingDate(date) {

      this.selectedDate = date;
      
      let body = {
        'dispatching_date': this.selectedDate
      }

      this.waitSpinner = true;

      axios.put(this.$apiUrl + "/portal/order/" + this.$route.params.barcode, body,
          { headers: this.requestHeaders }
        )
        .then((response) => {
          this.editShippingDate = false
          this.afterUpdate(response)
        })
        .catch((error) => this.afterUpdateError(error));

    },

    doPayment() {
      if (!this.order.sender.accept_payment) {
        this.toggleCard = true;
        return;
      }

      this.waitSpinner = true;

      axios.post(this.$apiUrl + "/portal/order/" + this.barcode + "/doPayment",
          {},
          { headers: this.requestHeaders }
        )
        .then((response) => {
          this.post_to_url(
            response.data.action,
            response.data.form_params,
            "post"
          );
        })
        .catch((error) => this.afterUpdateError(error));
    },

    post_to_url(path, params, method) {
      method = method || "post";

      var form = document.createElement("form");
      form.setAttribute("method", method);
      form.setAttribute("action", path);

      for (const [key, value] of Object.entries(params)) {
        var hiddenField = document.createElement("input");
        hiddenField.setAttribute("type", "hidden");
        hiddenField.setAttribute("name", key);
        hiddenField.setAttribute("value", value);

        form.appendChild(hiddenField);
      }

      document.body.appendChild(form);
      form.submit();
    },

    // Update Coords in API
    UpdateRequestCoords() {
      this.isButtonDisabled = true;
      this.waitSpinner = true;
      let headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + this.$route.query.access_token,
        Accept: "application/json",
      };
      let data = {
        receiver_address: {
          latitude: this.kordinatat[0],
          longitude: this.kordinatat[1],
        },
      };
      axios.put(this.$apiUrl + "/portal/order/" + this.$route.params.barcode, data,
          { headers: headers }
        )
        .then((response) => this.afterUpdate(response))
        .catch((error) => this.afterUpdateError(error));
    },

    // Check after response is.ok
    afterUpdate(response) {
      if (response) {
          this.isButtonDisabled = false;
          this.waitSpinner = false;
        Swal.fire({
          position: "top-end",
          icon: "success",
          title: "Ndryshimet janë bërë me sukses",
          showConfirmButton: false,
          timer: 2000,
        });

        axios
          .get(this.$apiUrl + "/portal/order/" + this.$route.params.barcode,
            {
              headers: this.requestHeaders,
            }
          )
          .then((response) => this.afterResponse(response.data))
          .catch((error) => console.log(error));
      }
    },

    // After this top request ->
    afterResponse(data) {
      this.order = data;
      this.positionMarkerOnUpdate.bindTooltip("<h1><span>Adresa e pranimit:</span><br>" + this.order.receiver_address.address + 
           " (" + this.order.receiver_address.city + ")</h1>").update();
      },
    // End
    
    // Check after response is error
    afterUpdateError(error) {
      if (error) {
        this.isButtonDisabled = false;
        this.waitSpinner = false;
        Swal.fire({
          icon: "warning",
          title:
            "Adresa nuk u ndryshua, kordinatat lejohen vetëm brenda Kosovës.",
          showConfirmButton: false,
          timer: 2500,
        });
      }
    },
    // End

    // Send Instructions
    sendInstructions() {
      this.waitSpinner = true;
      let headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + this.$route.query.access_token,
        Accept: "application/json",
      };
      let data = {
        delivery_instructions: this.driverInstructions,
      };
      axios.put(this.$apiUrl + "/portal/order/" + this.$route.params.barcode,
          data,
          { headers: headers }
        )
        .then((response) => this.afterInstructions(response))
        .catch((error) => this.afterInstructionsError(error));
    },

    // After instructions
    afterInstructions(response) {
      if (response) {
        this.waitSpinner = false;
        Swal.fire({
          icon: "success",
          title: "Instruksionet u dërguan",
          showConfirmButton: false,
          timer: 2500,
        });
      }
    },

    afterInstructionsError(error) {
      if (error) {
        this.waitSpinner = false;
        Swal.fire({
          icon: "warning",
          title: "Dicka shkoi keq!",
          showConfirmButton: false,
          timer: 2500,
        });
      }
    },
    // End

    // Update coords on map click
    updateCoordinates: function (lat, lng) {
      var ref = this;
      if (this.mapData.data) {
        this.mapData = "";
      }
      //var L = window.L;
      this.kordinatat = [lat, lng];
      this.positionMarker.setLatLng([lat, lng]).update();
      this.positionMarkerOnUpdate.setLatLng([lat, lng]).update();
      // this.mymapPosition.panTo(new L.LatLng(lat, lng));

      this.mymapPosition.flyTo([lat, lng], 18, {
        animate: true,
        duration: 1, // in seconds
      });

      this.mymap.flyTo([lat, lng], 18, {
        animate: true,
        duration: 1, // in seconds
      });

      ref.polyLine(this.positionMarkerDriver, this.positionMarkerOnUpdate);
      ref.clickedAddress(lat, lng);
      console.log("triggered");
      this.dragStarted = false;
    },
    // End

    // Toggle search
    toggleSearchfunc() {
      this.toggleSearch = !this.toggleSearch;
      setTimeout(() => this.$refs.searchInput.focus(), 100);
    },
    // End

    // On Option Selected city
    onOption: function (value) {
      for (var i = 0; i < this.qytetet.length; i++) {
        if (this.qytetet[i].cityName == value) {
          this.updateCoordinates(
            this.qytetet[i].cityLat,
            this.qytetet[i].cityLon
          );
          break;
        }
      }
    },
    // End

    // Validate map on modal click
    afterModal: function () {
      var ref = this;
      setTimeout(() => {
        this.mymapPosition.invalidateSize();
        this.mymapPosition.on("dragend", () => {
          this.dragStarted = false;
          ref.inWebLoad();
          var mapDragcoords = this.mymapPosition.getCenter();
          var lat = mapDragcoords.lat;
          var lng = mapDragcoords.lng;
          console.log("Latituda : " + lat + " Longituda: " + lng);
          ref.updateCoordinates(lat, lng);
          ref.clickedAddress(lat, lng);
        });
      }, 300);
    },
    // End

    // In webLoad
    inWebLoad() {
      this.kordinatat = [
        this.order.receiver_address.lat,
        this.order.receiver_address.long,
      ];
    },
    //

    // Get to google maps route
    gotoLocation: function () {
      var ref = this;
      if (navigator.geolocation) {
          navigator.geolocation.getCurrentPosition(ref.yourPosition);
      } else {
          alert("GPS nuk suportohet nga shfletuesi juaj!");
      }
    },
    yourPosition: function (vlera) {
      // window.open = 'https://nominatim.openstreetmap.org/ui/reverse.html?format=html&lon=' + Newvlera + '&zoom=18', '_blank';
      jQuery(
        '<a href="https://www.google.com/maps?saddr=' +
          vlera.coords.latitude +
          "," +
          vlera.coords.longitude +
          "&daddr=" +
          this.kordinatat +
          '" target="blank"></a>'
      )[0].click();
    },
    // End

    // Function to get my coordinates
    getMyLocation: function () {
      var ref = this;
      if (navigator.geolocation) {
          navigator.geolocation.getCurrentPosition(ref.getmyCoordinates);
      } else {
          alert("GPS nuk suportohet nga shfletuesi juaj!");
      }
      navigator.geolocation.watchPosition(
        function () {},
        function (error) {
          if (error.code == error.PERMISSION_DENIED)
            Swal.fire({
              position: "top-end",
              icon: "warning",
              title: "Ju nuk keni lejuar të marrim lokacionin tuaj!",
              text: "Për të ndryshuar adresën, kliko në hartë ose kërko me adresë",
              showConfirmButton: true,
              confirmButtonText: "Në rregull",
            });
        }
      );
    },

    getmyCoordinates: function (myCoords) {
      var ref = this;
      if (this.mapData.data) {
        this.mapData = "";
      }
      
      this.kordinatat = [myCoords.coords.latitude, myCoords.coords.longitude];

      this.positionMarker.setLatLng([myCoords.coords.latitude, myCoords.coords.longitude]).update();

      this.positionMarkerOnUpdate.setLatLng([myCoords.coords.latitude, myCoords.coords.longitude]).update();

      this.mymapPosition.flyTo([myCoords.coords.latitude, myCoords.coords.longitude], 18,
        {
          animate: true,
          duration: 1, // in seconds
        }
      );

      this.mymap.flyTo([myCoords.coords.latitude, myCoords.coords.longitude], 18,
        {
          animate: true,
          duration: 1, // in seconds
        }
      );

      ref.polyLine(this.positionMarkerDriver, this.positionMarkerOnUpdate);
      ref.clickedAddress(myCoords.coords.latitude, myCoords.coords.longitude);
    },
    // End

    // Search the map API
    searchmapMethod: function () {
      var self = this;
      this.thereisnodata = false;
      this.toggleLatLng = false;
      if (self.timer) clearTimeout(self.timer);

      this.mapData = {
        data: [
          {
            display_name: "Të dhënat po kërkohen! Ju lutem prisni...",
          },
        ],
      };

      self.timer = setTimeout(() => {
        axios
          .get("https://osm.cargosmart.cloud/search.php?&limit=10&countrycodes=XK&q=" + this.searchingAddress + "&viewbox=&format=json")
          .then((response) => this.sortData(response))
          .catch((error) => console.log(error));
      }, 100);
    },

    // Get street address from Coords by clicking on Map
    clickedAddress: function (lat, lng) {
      this.toggleSearch = false;
      axios
        .get("https://osm.cargosmart.cloud/reverse.php?lat=" + lat + "&lon=" + lng + "&viewbox=&format=json")
        .then((response) => (this.clickedAddressfunc(response)))
        .catch((error) => console.log(error));
    },
    // End

    clickedAddressfunc(response) {
      this.clickedCoordsAddress = response;
      this.positionMarker.bindTooltip("<h1><span>Adresa e përditësuar:</span><br> " + this.clickedCoordsAddress.data.display_name +
      "(" +   this.order.receiver_address.city + ")</h1>").update();
    },

    sortData: function (response) {
      if (response.data.length >= 1) {
          this.mapData = response;
          this.thereisnodata = false;
          this.toggleLatLng = true;
      } else {
          this.thereisnodata = true;
          this.mapData = "";
      }
    },
    // End

    // Driver's Marker
    markerDriverfunc: function (values) {
      var L = window.L;
      this.positionMarkerDriver = new L.marker(values, {
        icon: this.driverMarkerIcon,
      }).addTo(this.mymap);

      this.positionMarkerDriver.bindTooltip("<h1>Adresa ku ndodhet porosia</h1>");
    },
    // End

    // Make a polyline
    polyLine: function (driverCoords, orderCoords) {
      var L = window.L;

      if (this.positionMarkerDriver) {
          var latlngs = Array();

        // Driver Coords
        latlngs.push(driverCoords.getLatLng());

        // Order Coords
        latlngs.push(orderCoords.getLatLng());

        if (this.polyline) {
          this.mymap.removeLayer(this.polyline);
        }
        this.polyline = L.polyline(latlngs, { color: "#1478FE" }).addTo(
          this.mymap
        );
        // The line creation
        // zoom the map to the line
        // this.mymap.fitBounds(this.polyline.getBounds());
      }
    },
    // End

    // Get Qr code Source url
    getQrSource() {
      var getUrl = document.getElementById("qrCodeid").src;
      this.qrSource = getUrl;
    },
    // End
  },
  created() {
    // this.order.process_status = 6;
  },
  mounted() {
    var L = window.L;
    var ref = this;

    ref.inWebLoad();
    ref.getQrSource();

    // Map for delivery order
    this.mymap = L.map("map").setView(this.kordinatat, 17);
    L.tileLayer(
      "https://api.mapbox.com/styles/v1/{id}/tiles/{z}/{x}/{y}?access_token={accessToken}",
      {
        attribution:'Map data &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors, Imagery © <a href="https://www.mapbox.com/">Mapbox</a>',
        maxZoom: 18,
        id: "mapbox/streets-v11",
        tileSize: 512,
        zoomOffset: -1,
        accessToken:"pk.eyJ1IjoiZXJtaXJzaGFiYW5pIiwiYSI6ImNram1wajRpNzNvMjgydXNjMXd2NTU4OHAifQ.GVJgLjOQ52MRhhCHbQbSVg",
      }
    ).addTo(this.mymap);
    // Map for delivery order end

    // Map for changing the position by client end
    this.mymapPosition = L.map("map2").setView(this.kordinatat, 17);
    L.tileLayer(
      "https://api.mapbox.com/styles/v1/{id}/tiles/{z}/{x}/{y}?access_token={accessToken}",
      {
        attribution:'Map data &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors, Imagery © <a href="https://www.mapbox.com/">Mapbox</a>',
        maxZoom: 20,
        id: "mapbox/streets-v11",
        tileSize: 512,
        zoomOffset: -1,
        accessToken: "pk.eyJ1IjoiZXJtaXJzaGFiYW5pIiwiYSI6ImNram1wajRpNzNvMjgydXNjMXd2NTU4OHAifQ.GVJgLjOQ52MRhhCHbQbSVg",
      }
    ).addTo(this.mymapPosition);
    // Map for changing the position by client end

    // Icon for maker
    this.markerIcon = L.icon({
      iconUrl: require("../../assets/icon/marker-centered.png"),
      iconSize: [45, 55], // size of the icon
    });

    this.markerIconFade = L.icon({
      iconUrl: require("../../assets/icon/marker-centered.png"),
      iconSize: [0, 0], // size of the icon
    });

    // Driver Marker
    this.driverMarkerIcon = L.icon({
      iconUrl: require("../../assets/icon/driverMarker.png"),
      iconSize: [35, 55], // size of the icon
    });
    // End

    // Position marker
    
    this.positionMarker = new L.marker(this.kordinatat, {
      icon: this.markerIconFade,
    }).addTo(this.mymapPosition); 

    this.positionMarkerOnUpdate = new L.marker(this.kordinatat, {
      icon: this.markerIcon,
    }).addTo(this.mymap);

      this.positionMarker
      .bindTooltip("<h1><span>Adresa e pranimit:</span><br> " + this.order.receiver_address.address +
         " (" + this.order.receiver_address.city + ")</h1>").openTooltip();

    this.positionMarkerOnUpdate
      .bindTooltip("<h1><span>Adresa e pranimit:</span><br> " + this.order.receiver_address.address +
         " (" + this.order.receiver_address.city + ")</h1>").openTooltip();
    // End

    // Map when the drag or zoom starts
    this.mymapPosition.on("dragstart", () => {
       this.thereisnodata = false;
       this.dragStarted = true;
       this.positionMarker.closeTooltip();
    });
    
    this.mymapPosition.on("zoomstart", () => {
      this.thereisnodata = false;
      this.positionMarker.closeTooltip();
    });

    this.mymapPosition.on("zoomend", () => {
      this.positionMarker.openTooltip();
    });
    // End

    // Call function on webLoad to check for status and make polyline between driver and order positions
    if (this.order.process_status == 7) {
        this.driverCoords = [this.order.agent.lat, this.order.agent.long];
        ref.markerDriverfunc(this.driverCoords);
        ref.polyLine(this.positionMarkerDriver, this.positionMarkerOnUpdate);
    } else if (this.order.process_status <= 6) {
      this.driverCoords = [
        this.order.pickup_address.lat,
        this.order.pickup_address.long,
      ];
      ref.markerDriverfunc(this.driverCoords);
      ref.polyLine(this.positionMarkerDriver, this.positionMarkerOnUpdate);
    }
    // End
  },
};
</script>
