<template>
     <div class="progressDiv">
                <div class="progress-track">
                  <ul id="progressbar">
                    <li
                      class="step0"
                      id="step1"
                      v-bind:class="{
                        active: order.process_status >= 1,
                   
                        trans: order.process_status >= 1 && order.process_status <= 3,

                      }"
                    >
                      <!-- Porosia u vendos -->
                    </li>
                    <li
                      class="step0 text-center"
                      id="step2"
                      v-bind:class="{
                        active: order.process_status >= 4,
                       
                        trans: order.process_status >= 4 && order.process_status <= 5,

                      }"
                    >
                      <!-- Në tranzit -->
                    </li>
                    <li
                      class="step0 text-center"
                      id="step3"
                      v-bind:class="{
                        active: order.process_status >= 6,
                      
                        trans: order.process_status >= 6 && order.process_status <= 7,

                      }"
                    >
                      <!-- <span id="three"> E nisur </span> -->
                    </li>
                    <li
                      class="step0 text-right"
                      id="step4"
                      v-bind:class="{
                        active: order.process_status >= 8,
                        activeDeclined:
                          order.process_status >= 9,
                        success: order.process_status == 8,
                        trans: order.process_status == 8,

                      }"
                    >
                    <span v-if="order.process_status == 8" id="check"><i class="fas fa-check"></i></span>
                      <!-- E dërguar -->
                    </li>
                  </ul>
                </div>
              </div>
</template>


<script>
export default {
     props: ["myOrder"],
     data() {
         return {
             order: this.myOrder.data,
         }
     }

}
</script>