<template>
  <div>

    <div class="preloader" id="preloader" v-if="loadingSpinner == true">
      <img src="../../assets/Images/spinner.svg" alt="">
    </div>

    <div>
      <rateDriverPage v-if="goTorate == true" :myOrder="myOrder">
      </rateDriverPage>
    </div>

    <div v-if="goTodetails == true">
      <orderDetailsPage :myOrder="myOrder"></orderDetailsPage>
    </div>
  </div>
</template>

<script>
import orderDetailsPage from "./order-details-withoutlogin.vue";
import rateDriverPage from "./rate-driver-withoutlogin.vue";
import axios from "axios";

export default {
  data() {
    return {
      goTodetails: null,
      goTorate: null,
      myOrder: null,
      loadingSpinner: true,
      tokenOrder: this.$route.query.token,
    };
  },
  components: {
    orderDetailsPage,
    rateDriverPage,
  },
  methods: {
    
    checkRoute() {
      if (!this.$route.params.barcode) {
        this.$router.replace("404");
      }
    },

    checkResponse(response) {
      this.myOrder = response;
      this.goTodetails = true;
      
      setTimeout(() => {
        this.loadingSpinner = false;
      }, 700);

      if (this.myOrder.data.process_status >= 8 && this.myOrder.data.process_status <= 10) {
        
        this.goTorate = true;

      }

    },
    checkError() {
      this.$router.push("../../404");
    },
    makeRequest() {
      axios
      .get(this.$apiUrl + "/portal/order/" + this.$route.params.barcode, {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + this.$route.query.access_token,
          Accept: "application/json",
        },
      })
      .then((response) => this.checkResponse(response))
      .catch((error) => this.checkError(error));
    },
  },
  mounted() {
    this.checkRoute();
    this.makeRequest();

  },
};
</script>
