<template>
  <div class="vleresoShoferin guest divBody">

 <div class="preloader" id="preloader" v-if="waitSpinner == true">
        <img src="../../assets/Images/spinner.svg" alt="">
    </div>

    <div class="container headerCC">
      <div class="row">
        <div class="col col-12">
          <div class="headerImage">
              <img src="../../assets/Images/npost-logo.png" alt="" />
            <p><span>N'</span>Llogaria</p>
          </div>
        </div>
      </div>
    </div>

    <div class="container animate__animated animate__fadeInRight">
      <div class="row">
        <!-- Order informations -->
        <div class="col col-12">
          <div class="orderInfo">
      
              <div class="status dsc">
              <h1>Statusi:</h1>

              <p v-if="order.process_status <= 3">Porosia e regjistruar</p>
              <p
                v-else-if="
                  order.process_status >= 3 && order.process_status <= 5
                "
              >
                Porosia është në bazë
              </p>
              <p
                v-else-if="
                  order.process_status >= 6 && order.process_status <= 7
                "
              >
                Porosia është nisur
              </p>
              <p v-else-if="order.process_status == 8">
                Porosia është pranuar nga klienti
              </p>
              <p v-else-if="order.process_status == 9">Dërgesa ka dështuar</p>
              <p v-else-if="order.process_status == 10">
                Dërgesa nuk u pranua
              </p>
              <p v-else-if="order.process_status == 11">
                Porosia është anuluar
              </p>
              <p v-else-if="order.process_status == 12">
                Produkti është rikthyer te dërguesi
              </p>
                         <progressbar :myOrder="myOrder"></progressbar>

            </div>



            <div class="barCode dsc">
              <h1>Numri i porosisë:</h1>
              <p>{{ myOrder.data.barcode }}</p>
            </div>

            <div class="method dsc">
              <h1>Metoda e dergeses:</h1>
              <p v-if="order.delivery_method == 'store-home'">
                <img src="../../assets/icon/store-icon.png" alt="" />
                Shitore
                <i class="fas fa-long-arrow-alt-right"></i>
                <img src="../../assets/icon/home-icon.png" alt="" />
                Shtëpi
              </p>
            </div>

            <div class="driverName dsc">
              <h1>Emri i postierit:</h1>
              <p>{{ myOrder.data.agent.agent_name }}</p>
            </div>
          </div>
          <hr />
        </div>
        <!-- Order Informations End -->

        <!-- Rate -->

        <div class="col col-12" v-if="hasRated != undefined">
          <div class="hasRated">
            <div
              class="alert alert-success alert-dismissible fade show"
              role="alert"
              style="background-color: rgb(180 251 155); "
            >
              <b style="font-weight:500">Ky shërbim është vlerësuar! </b> 
            
            </div>
          </div>
        </div>

        <div class="col col-12 rateCol" v-if="hasRated == undefined && rateShow == true">
          <div class="rateService" v-if="!isClicked == true">
            <div class="title">
              <h1>
                Si ishte shërbimi i postierit?
              </h1>
            </div>

            <div class="ddR">
              <div class="txt">
                <h1>
                  Vlerësimi juaj na ndihmonë në përmirësimin e shërbimeve tona!
                </h1>
              </div>
              <div class="emojiDiv">
          
                <span
                 v-for="(rating, index) in emojiRate"
                  :key="index.rating"
                  >
                  <img
                  :src="rating.image"
                  alt=""
                  @click="rateEmoji(index + 1, rating)"
                  v-bind:class="{ active: isActive === index + 1 }"
                />
                <p :id="'value' + index"> 
                  {{rating.value}}
                </p>
                </span>
             
              </div>
            </div>
          </div>

          <div class="afterClick" v-if="isClicked == true">
            <div class="divTxt">
              <h1>{{ afterClick }}</h1>
              <img :src="selectedEmoji" alt="" />
            </div>

            <div class="divWhy">
              <p>Cilat janë arsyet e vlerësimit?</p>
            </div>

            <div class="divReasons">
              <div
                class="myReason"
                @click="addReasonsFunction(value, index)"
                v-for="(value, index) in reasons"
                :key="index.value"
              >
                <button
                  v-bind:class="{
                    activeReason: addReasons.reasons.find(
                      (x) => x.value === value
                    ),
                  }"
                >
                  <span
                    class="pointIcon"
                    v-if="!addReasons.reasons.find((x) => x.value === value)"
                    ><i class="far fa-hand-point-right"></i
                  ></span>
                  <span v-if="addReasons.reasons.find((x) => x.value === value)"
                    ><i class="fas fa-check"></i
                  ></span>
                  {{ value }}
                </button>
              </div>

              <div class="myReason">
                <button
                  v-bind:class="{ activeReason: triggerComment == true }"
                  @click="triggerComment = !triggerComment"
                >
                  <span v-if="triggerComment != true"
                    ><i class="fas fa-plus"></i
                  ></span>
                  <span v-if="triggerComment == true"
                    ><i class="fas fa-check"></i>
                  </span>
                  Shtoni një koment për shërbimin
                </button>
              </div>
            </div>

            <div class="addcomment" v-if="triggerComment == true">
              <div class="myReason">
                <textarea
                  name="comment"
                  v-model="commentText"
                  id="comment"
                  cols="20"
                  rows="5"
                  placeholder="Tregoni arsyen tuaj këtu!"
                ></textarea>
              </div>
            </div>

            <hr />

            <div class="divBtn">
              <button
              class="btnClose"
               @click="returnRating()">
                <i class="fas fa-times"></i> Mbylle
              </button>

              <button
                v-if="
                  addReasons.reasons.length > 0 || addReasons.rating.length > 0
                "
                class="btnGo"
                @click="demoRate()"
              >
                <i class="fas fa-paper-plane"></i> Vlerëso
              </button>
            </div>
          </div>
        </div>

        <!-- Rate End -->
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Swal from "sweetalert2";
import progressbar from './progess-bar.vue';
export default {
  components: { progressbar },
 
  props: ["myOrder"],
  data() {
    return {
      waitSpinner: null,
      order: this.myOrder.data,
      hasRated: this.myOrder.data.agent.reviews,
      isActive: null,
      isClicked: false,
      triggerComment: false,
      commentText: "",
      afterClick: "",
      rateShow: true,
      selectedEmoji: null,
      reasons: [],
      addReasons: {
        agent_id: this.myOrder.data.agent.agent_id,
        reasons: [],
      },
      passwordCorrect: false,
      yourInput: null,
      orderPassword: 415,

      emojiRate: [
        {value: 'Shumë keq', image: require("../../assets/icon/angry-face-emoji.png")},
        {value: 'Keq', image: require("../../assets/icon/sad-face-emoji.png")},
        {value: 'Mirë', image:  require("../../assets/icon/meh-face-emoji.png")},
        {value: 'Shumë mirë', image:  require("../../assets/icon/smiling-face-emoji.png")},
        {value: 'Shkëlqyeshëm', image: require("../../assets/icon/satisfied-face-emoji.png")}, 
      ],
    };
  },
  created() {
    // this.hasRated = undefined;
    //this.order.process_status = 6;
  },
  methods: {
    // Add reasons when clicking
    addReasonsFunction: function(value) {
      this.isReason = true;
      const indeksi = this.addReasons.reasons.find((x) => x.value === value);
      const findIndex = this.addReasons.reasons.indexOf(indeksi);

      if (indeksi != undefined) {
        this.addReasons.reasons.splice(findIndex, 1);
      } else {
        this.addReasons.reasons.splice(findIndex, 0, { value: value });
      }
    },
    // End

    // Demo rate
    demoRate() {
          this.rateShow = false;
          this.waitSpinner = true;
      if (this.commentText.length > 0) {
        this.addReasons.reasons.push({ value: this.commentText });
      }
      let headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + this.$route.query.access_token,
        Accept: "application/json",
      };

      axios
        .post(
          this.$apiUrl + 
          "/portal/order/" +
            this.$route.params.barcode +
            "/rate",
          this.addReasons,
          { headers: headers }
        )
        .then((response) => this.afterUpdate(response))
        .catch((error) => this.afterUpdateError(error));
    },

    afterUpdate(response) {
      if (response) {
        this.waitSpinner = false;
        Swal.fire({
          position: "top-end",
          icon: "success",
          title: "Ju faleminderit",
          text: "Ju vlerësuat shërbimin tonë.",
          showConfirmButton: false,
          timer: 2500,
        });
        this.hasRated = !undefined;
      }
    },

    afterUpdateError(error) {
       if (error) {
        this.waitSpinner = false;
        Swal.fire({
          position: "top-end",
          icon: "warning",
          title: "Dicka shkoi keq!",
          text: "Ju lutemi provoni përsëri ose më vonë",
          showConfirmButton: false,
          timer: 2500,
        });
        this.hasRated = !undefined;
      }
    },
    // End

    // When clicked emoji hide and show
    returnRating: function() {
      this.isClicked = false;
      this.isReason = false;
      this.triggerComment = false;
      this.addReasons = {
        agent_id: this.myOrder.data.agent.agent_id,
        reasons: [],
      };
    },
    rateEmoji: function(index, rating) {
      this.isActive = index;
      this.isClicked = true;
      this.selectedEmoji = rating.image;
      this.reasons = [];
      this.addReasons = {
        agent_id: this.myOrder.data.agent.agent_id,
        rating: index,
        reasons: [],
      };
      //  this.addReasons.push({ ratingValue: index });
      if (index == 1) {
        this.afterClick =
          "( Nota: " +
          index +
          " ) " +
          "Na vjen shumë keq për eksperiencën tuaj...";
        this.reasons.push(
          "Sjellja",
          "Shërbimi",
          "Nuk përgjigjej",
          "Vonesa",
          "Kam paguar më shumë se që duhet"
        );
      } else if (index == 2) {
        this.afterClick =
          "( Nota: " + index + " ) " + "Cilat ishin pakënaqësit e tua?";
        this.reasons.push(
          "Sjella!",
          "Nuk përgjigjej!",
          "Produkti!",
          "Kam paguar më shumë se që duhet!"
        );
      } else if (index == 3) {
        this.afterClick =
          "( Nota: " +
          index +
          " ) " +
          "Faleminderit për vlerësimin tuaj! Do të përmirësohemi edhe më shumë";
        this.reasons.push(
          "U vonua pak!",
          "Sjellje jo e edhe e mirë!",
          "Nuk e dinte adresën mirë!",
          "Shpresoja të ishte shërbim më i mirë!"
        );
      } else if (index == 4) {
        this.afterClick =
          "( Nota: " +
          index +
          " ) " +
          "Na vjenë mirë për vlerësimin tuaj! Si mundemi të përmirësohemi ende më shumë?";
        this.reasons.push(
          "I saktë në adresë!",
          "I sjellshëm!",
          "Shërbim i shpejtë!"
        );
      } else if (index == 5) {
        this.afterClick =
          "( Nota: " +
          index +
          " ) " +
          "Faleminderit! Shpresojmë të punojmë përsëri me ju.";
        this.reasons.push(
          "I sjellshëm!",
          "Ishte i shpejtë!",
          "Komunikim i mirë!",
          "Shërbim fantastik!"
        );
      }
    },
    // End
  },
};
</script>
