<template>
    <div class="card" v-if="order.can_change_eta">
        <div class="card-body">
            <template v-if="!editShippingDate">
                <div>
                    <p>Data e parashikuar e dorëzimit</p>
                    <h5>{{ moment(order.dispatching_date).format('DD/MM/YYYY') }}</h5>
                </div>
            
                <template v-if="order.change_eta_limit.length > 0">
                    <button 
                        class="btn btn-block btn-small btn-text"
                        @click="editShippingDate = true"
                    >
                        Deshironi ta ndryshoni daten?
                    </button>
                    
                    <p class="small">Nëse nuk mund ta pranoni pakon në këtë datë, ndryshoje në një datë tjetër.</p>
                </template>
            </template>
            <template v-else>
                <div>
                    <p>Data e parashikuar {{ moment(order.dispatching_date).format('DD/MM/YYYY') }}</p>
                </div>
                <div class="d-flex justify-content-center flex-wrap new-date-container">
                    <div class="d-flex flex-column align-items-center date-item"
                        v-for="date in order.change_eta_limit"
                        :key="date"
                        @click="selectedDate = date"
                        :class="selectedDate == date ? 'selected' : ''"
                    >
                        <div class="day small">{{ moment(date).format('ddd') }}</div>
                        <div class="date font-weight-bold">{{ moment(date).format('DD') }}</div>
                        <div class="month small">{{ moment(date).format('MMM') }}</div>
                    </div>
                </div>

                <div class="d-flex justify-content-end align-items-center mt-2" style="gap:20px;"> 
                    <button style="width:100%"  @click="changeShippingDate()">
                        Ruaje
                    </button>
                            
                    <button 
                        class="ml-2"
                        style="width:100%; background-color:#6c757d"
                        @click="(editShippingDate = false)"
                    >
                        Anulo
                    </button>
                </div>
                
            </template>
        </div>
    </div>
</template>

<script>
export default {
    props:['order'],
    data() {
        return {
            selectedDate: this.order.dispatching_date,
            editShippingDate:false,
            toggleCard: false,
        }
    },
    mounted() {
        this.moment.locale('sq');
    },
    methods: {
        changeShippingDate() {
            this.editShippingDate = false;
            this.$emit('updateDate',this.selectedDate);
        }
    }
}
</script>

<style>
    .new-date-container {
        gap:20px;
    }

    .new-date-container .date-item {
        border: 1px solid rgba(0, 0, 0, 0.125);
        padding:10px;
        width:60px;
        cursor: pointer;
    }

    .new-date-container .date-item.selected {
        background-color: #1478FE;
        color:#fff;
        outline: none;
    }
</style>