var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"progressDiv"},[_c('div',{staticClass:"progress-track"},[_c('ul',{attrs:{"id":"progressbar"}},[_c('li',{staticClass:"step0",class:{
                   active: _vm.order.process_status >= 1,
              
                   trans: _vm.order.process_status >= 1 && _vm.order.process_status <= 3,

                 },attrs:{"id":"step1"}}),_c('li',{staticClass:"step0 text-center",class:{
                   active: _vm.order.process_status >= 4,
                  
                   trans: _vm.order.process_status >= 4 && _vm.order.process_status <= 5,

                 },attrs:{"id":"step2"}}),_c('li',{staticClass:"step0 text-center",class:{
                   active: _vm.order.process_status >= 6,
                 
                   trans: _vm.order.process_status >= 6 && _vm.order.process_status <= 7,

                 },attrs:{"id":"step3"}}),_c('li',{staticClass:"step0 text-right",class:{
                   active: _vm.order.process_status >= 8,
                   activeDeclined:
                     _vm.order.process_status >= 9,
                   success: _vm.order.process_status == 8,
                   trans: _vm.order.process_status == 8,

                 },attrs:{"id":"step4"}},[(_vm.order.process_status == 8)?_c('span',{attrs:{"id":"check"}},[_c('i',{staticClass:"fas fa-check"})]):_vm._e()])])])])}
var staticRenderFns = []

export { render, staticRenderFns }